import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { Customerlist } from "../../services/customer.service";
import {
  updateBrandStatus,
  Merchantlist,
} from "../../services/merchant.service";
import { useTranslation } from "react-i18next";

const ViewCustomerDetail = ({ brand = {}, back }) => {
  const { t, i18n } = useTranslation(); // Translation hook

  const { id } = useParams();
  const [customer, setCustomer] = useState();
  console.log("customer::: ", customer);

  const mobileNumber = customer?.[0].mobile || "-";
  const cleanedNumber = mobileNumber.replace(/\D/g, "");
  //get mobilr formatmobile number
  const formatMobileNumber = (number) => {
    if (number === "-") return "-";

    // Handle numbers starting with specific country codes
    if (
      number.startsWith("965") ||
      number.startsWith("966") ||
      number.startsWith("971") ||
      number.startsWith("385")
    ) {
      return `+${number.substring(0, 3)}-${number.substring(3)}`;
    } else if (number.startsWith("91")) {
      return `+91-${number.substring(2)}`;
    } else {
      return `+${number}`;
    }
  };
  const formattedNumber = formatMobileNumber(cleanedNumber);

  //get country name

  const countryMapping = {
    91: "India",
    965: "Kuwait",
    966: "Saudi Arabia",
    971: "United Arab Emirates",
    "+1": "United States of America",
    385: "Croatia",
  };

  // Extract mobile number and clean it
  const mobileNumber1 = customer?.[0]?.mobile || "-";
  const cleanedNumber1 =
    mobileNumber !== "-" ? mobileNumber.replace(/\D/g, "") : "-";

  const getCountryName = (number) => {
    if (number === "-") return "-";

    // Match the cleaned number with country codes
    for (const [code, country] of Object.entries(countryMapping)) {
      if (number.startsWith(code)) {
        return country;
      }
    }

    return "-"; // Default if no match found
  };

  // Get the country name
  const countryName = getCountryName(cleanedNumber);

  const getAllCustomerById = async (id) => {
    try {
      const resp = await Customerlist(id);

      if (resp?.status === 200 && resp?.data?.statusCode === 200) {
        setCustomer(resp?.data?.data?.getAllCustomerById);
      } else {
        toast.error(resp?.message || resp?.data?.message);
      }
    } catch (error) {
      if (error?.message) {
        const msg = error?.message?.split(":")?.[1]?.trim();

        console.error(msg);
      }
      console.log(error.message);
    }
  };

  useEffect(() => {
    getAllCustomerById(id);
  }, []);

  return (
    <div className="tableResponsive">
      <table className="table_detail">
        <tbody>
          <tr>
            <th>{t("common.FirstName")}</th>
            <td>{customer?.[0].first_name || "-"}</td>
          </tr>
          <tr>
            <th>{t("common.LastName")}</th>
            <td>{customer?.[0].last_name || "-"}</td>
          </tr>
          <tr>
            <th>{t("customer.Mobile")}</th>
            <td>{formattedNumber || "-"}</td>
          </tr>
          <tr>
            <th>{t("common.EmailAddress")}</th>
            <td>{customer?.[0].email || "-"}</td>
          </tr>
          <tr>
            <th>{t("common.Country")}</th>
            <td>{countryName || "-"}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ViewCustomerDetail;
