import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";

import { EditCampaignDetail } from "../../services/campaign.service";
import SaveIcon from "../../assets/images/save_icon.svg";

import BackIcon from "../../assets/images/back_icon.svg";
import { CreateCategory } from "../../services/Category.service";
import CloseIcon from "../../assets/images/close_icon.svg";
import UploadImg from "../../assets/images/upload_img.svg";
import { fileUpload } from "../../services/common.service";
import ArrowleftIcon from "../../assets/images/arrowleft_icon.svg";


const fileType = "Voucher";

const EditAdmin = ({ data = {}, back }) => {
 

  const handleClose = () => {
    resetForm();
    setErrors({});
    back(false);
  };

  const today = new Date().toISOString().split("T")[0];

  const {
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    values,
    errors,
    touched,
    resetForm,
    setErrors,
  } = useFormik({
    initialValues: {
      category: data.category_name,
      category_logo: data?.image,
      selectedLogo: data?.image,
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      category: Yup.string()
      // .matches(/^[a-zA-Z0-9\s]*$/, 'Only alphanumeric characters and spaces are allowed')
      //   .max(50, "Category Name must be at most 50 characters")
        .required("Category name is required"),
      category_logo: Yup.string().required("logo is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
     
      try {
        const payload = {
          ...values,
          id: data.id,
          campaign_logo: values?.logo,
          // selectedLogo: data?.image,
         
        };

        const res = await CreateCategory(payload);

        if (res?.status === 200 && res?.data?.statusCode === 200) {
          toast.success(res.data.message);
          resetForm();
          back();
        } else {
          toast.error(res?.message || res?.data?.message);
        }
      } catch (error) {
        if (error?.message) {
          const msg = error?.message?.split(":")?.[1]?.trim();
          console.error(msg);
        }
        console.log(error.message);
      }
    },
  });
  const handleTypeChange = (e) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
  };

  // ________

  const handleFileChange = async (event) => {
    try {
      const selectedFile = event.target.files[0];
      if (selectedFile) {
        const formData = new FormData();
        formData.append("file", selectedFile, selectedFile.name);
        let res = await fileUpload(formData, fileType);
      
        if (res?.data?.statusCode === 200) {
          // setFieldValue("logo", res?.data?.data);
          setFieldValue("category_logo", res?.data?.data);
          setFieldValue("selectedLogo", URL.createObjectURL(selectedFile));
        }
      }
    } catch (error) {}
  };

  const removeLogo = () => {
    setFieldValue("logo", "");
    setFieldValue("selectedLogo", "");
    setFieldValue("category_logo", "");
  };

  return (
    <>
      {/* <div className="mb_24 displayFlex alignItem_center gap12"> */}
        {/* <div>
          <button
            className="emptyBtn"
            type="button"
            onClick={handleClose}
          >
            <img src={ArrowleftIcon} alt="ArrowleftIcon" />
          </button>
        </div>
        <div>
          <h5 className="fontSize20 fontWeight600 blackClr_text">
            Edit Category
          </h5>
        </div> */}
        
      {/* </div> */}

      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="mb_24 col-md-6">
            <label
              className="fontSize14 fontWeight500 blackClr_text display_block mb_8"
              htmlFor="fn"
            >
              Category Name
            </label>
            <input
              className="MainInput"
              type="text"
              placeholder="Enter Category Name"
              id="category"
              name="category"
              value={values.category}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            {errors.category && touched.category ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.category}
              </p>
            ) : (
              <></>
            )}
          </div>

          <div className="mb_24 col-md-6">
            <label className="fontSize14 fontWeight500 blackClr_text display_block mb_8">
              Category Logo
            </label>
            {!values?.selectedLogo && (
              <label htmlFor="campaign_logo" className="uploadImg_btn gap12">
                <img src={UploadImg} alt="UploadImg" />
                <span>Upload Category Logo</span>
                <input
                  id="campaign_logo"
                  type="file"
                  name="campaign_logo"
                  value={values.selectedFile}
                  accept="image/*" // Accept all image file types
                  onChange={handleFileChange}
                  hidden
                />
              </label>
            )}

            {values?.selectedLogo && (
              <div className="ImgFrame position_relative">
                <img
                  className="brandImgSelectlogo"
                  src={values?.selectedLogo}
                  width={100}
                  height={100}
                  alt="selected logo"
                />
                <div className="imgcloseBtn ">
                  <img src={CloseIcon} alt="CloseIcon" onClick={removeLogo} />
                </div>
              </div>
            )}

            {!values.logo && touched.logo ? (
              <p className="fontSize12 fontWeight500 errorClr_text mt_8">
                {errors.logo}
              </p>
            ) : (
              <></>
            )}
          </div>
        </div>

        {/* ========= Submit Button ========= */}
        <button type="submit" className="MainButton cyanSky_clr gap12">
          <img src={SaveIcon} alt="SaveIcon" />
          <span> Save Changes</span>
        </button>
      </form>
    </>
  );
};

export default EditAdmin;
