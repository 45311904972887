import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { exportTransactions, transaction, transactionbyid } from "../../services/Bank.service";
import PaginatedTable from "../common/PaginatedTable";
import Viewalltransaction from "../transaction/Viewalltransaction";
import ViewIcon from "../../assets/images/view_icon.svg";
import { formatCurrency } from "../../utils/helpers/helper";
import { convertToISOString } from "../../services/common.service";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';  // Import useTranslation

function AllTransaction() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation(); // Translation hook
  const [transactions, setTransactions] = useState(null);
  const [transactionstable, setTransactionstable] = useState(true);
  const [transactionview, setTransactionView] = useState(false);
  const [data, setdata] = useState(transactions);
  const [purchasetype, setpurchasetype] = useState("");


  //Paginated Table Variables
  let [totalCount, setTotalCount] = useState(0);
  let [currentPage, setCurrentPage] = useState(1);
  let [itemsPerPage, setItemsPerPage] = useState(10);
  let [searchKey, setSearchKey] = useState("");
  let [isLoading, setIsLoading] = useState(true);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  const [range, setRange] = useState({
    startDate: undefined,
    endDate: new Date(),
  });

  //Defining the Columns of the Paginated Tables

  const handleExportTransactions = async () => {
    try {
      let payload = {
        startDate: convertToISOString(range.startDate),
        endDate: convertToISOString(range.endDate),
        search: searchKey
      }
      const data = await exportTransactions(payload)
    } catch (error) {
      console.log("ERROR", error)
    }
  }

  const handleShowView = (tdetails) => {
    // setdata(transactions)
    // console.log("tdetails::: ", tdetails);
    // sessionStorage.setItem(
    //   "master_transaction_id",
    //   tdetails.master_transaction_id
    // );
    setTransactionstable(false);
    // setdata(tdetails);
    setTransactionView(true);
  };

  const [column, setColumn] = useState([
    {
      heading: () => t("common.Action"),
      cell: (row) => (
        <>
          <div>
            <button className="emptyBtn" onClick={() => navigate(`/admin/View-transaction/${row?.master_transaction_id}/${row?.merchant_name}`)}>
              <img
                src={ViewIcon}
                alt="ViewIcon"
              // onClick={() => handleShowView(row.master_transaction_id,row.customer_name)}

              />
            </button>

          </div>
        </>


      ),
      hidden: false,
      // width: "100",
      // align: "cemter",
      id: 1,
    },

    {
      heading: () => t("transaction.TransactionId"),
      cell: (row) =>
        row["master_transaction_id"] ? `Krti_${row["master_transaction_id"]}` : "-",
      hidden: false,
      width: "120",
      align: "left",
      id: 2,
    },
    {
      heading: () => t("customer.CustomerName")+"/"+t("customer.CustomerMobile"),
      cell: (row) =>
        row["customer_name"] === " " ? "-" : row["customer_name"],
      hidden: false,
      width: "120",
      align: "center",
      id: 3,
    },
    {
      heading: () => t("merchant.MerchantName"),
      cell: (row) => (<div>
        <a
           className="emptyBtn cursorPointer blackClr_text"
          onClick={() => navigate(`/admin/brand-detail/${row?.merchant_id}`)}
        >
          {row["merchant_name"]}
        </a>
      </div>),
      // row["merchant_name"] ? row["merchant_name"] : "-",
      hidden: false,
      width: "120",
      align: "left",
      id: 4,
    },

    {
      heading: () => t("common.Amount"),
      cell: (row) => {
        const amount = row["total_amount"];
        return amount ? formatCurrency(amount) : "-";
        // return row['total_amount']
      },
      hidden: false,
      width: "100",
      id: 5,
    },
    {
      heading: () => t("transaction.PurchaseType"),
      cell: (row) =>
        row["purchase_type"] ? row["purchase_type"] : "-",
      hidden: false,
      width: "120",
      align: "left",
      id: 6,
    },
    {
      heading: () => t("transaction.PaymentDateTime"),
      
      cell: (row) => {
        if (row["paymentDate"]) {
          // Assuming createdDate is in the format "DD-MM-YYYY HH:MI:SS"
          const [date, time] = row["paymentDate"].split(' ');
          return `${date} ${time}`;
        } else {
          return "-";
        }
      },
      hidden: false,
      width: "120",
      align: "left",
      id: 7,
    },

  ]);

  //End of Paginated Table Variables
  const getAllTransactions = async () => {
    const offset = (currentPage - 1) * itemsPerPage;
    const offsetToUse = offset >= 0 ? offset : 0;
    // offset = (currentPage - 1) * itemsPerPage;
    try {
      const requestBody = {
        offset: offsetToUse,
        limit: itemsPerPage,
        startDate: convertToISOString(range.startDate),
        endDate: convertToISOString(range.endDate),
        search: searchKey,
        statusfilter: purchasetype || "all"
        
      };
      const resp = await transactionbyid(requestBody);

      if (resp?.status === 200 && resp?.data?.statusCode === 200) {
        setTransactions(resp?.data?.data);
        setTotalCount(resp?.data?.count);
      } else {
        toast.error(resp?.message || resp?.data?.message);
      }
    } catch (error) {
      if (error?.message) {
        const msg = error?.message?.split(":")?.[1]?.trim();

        console.error(msg);
      }
      console.log(error.message);
    }
  };
  useEffect(() => {
    getAllTransactions();
  }, [currentPage, itemsPerPage, searchKey, range, setSearchKey,purchasetype]);
  return (
    <div>
      {/* {
        voucherTable && */}

      {transactionstable && (
        <>
          <div className="mb_24 displayFlex alignItem_center justifyContent_spacebetween">
            <div>
              <h5 className="fontSize20 fontWeight600 blackClr_text">
                {" "}
                Transactions List
              </h5>
            </div>
          </div>
          {isLoading ? (
            <PaginatedTable
              row={transactions}
              column={column}
              totalCount={totalCount}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              isSearch={true}
              searchKey={searchKey}
              setSearchKey={setSearchKey}
              maxPageNumberLimit={maxPageNumberLimit}
              setMaxPageNumberLimit={setMaxPageNumberLimit}
              minPageNumberLimit={minPageNumberLimit}
              setMinPageNumberLimit={setMinPageNumberLimit}
              setRange={setRange}
              range={range}
              isExport={true}
              isDateRange={true}
              handleExport={() => handleExportTransactions()}
              ispurchasetype={true}
              purchasetype={purchasetype}
              setpurchasetype={setpurchasetype}
              
            />
          ) : (
            <div style={{ textAlign: "center" }}>Loading...</div>
          )}
        </>
      )}

      {/* {transactionview && <Viewalltransaction  data={transactions} /> } */}
    </div>
  );
}

export default AllTransaction;
