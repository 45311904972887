import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { getCustomerList } from "../../services/super-admin.service";
import { useNavigate } from "react-router-dom";
import ViewIcon from "../../assets/images/view_icon.svg";
import PaginatedTable from "../common/PaginatedTable";
import DatePicker from "../DatePicker/DatePicker";
import { exportCustomer } from "../../services/export.service";
import { convertToISOString } from "../../services/common.service";
import { useTranslation } from "react-i18next"; // Import useTranslation

const Customerpage = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation(); // Translation hook

  const [list, setList] = useState([]);

  // Paginated Table Variables
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchKey, setSearchKey] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
  const [range, setRange] = useState({
    startDate: undefined,
    endDate: new Date(),
  });
  const [status, setStatus] = useState("");
  const handleExport = async () => {
    try {
      let payload = {
        startDate: convertToISOString(range.startDate),
        endDate: convertToISOString(range.endDate),
        search: searchKey,
      };
      await exportCustomer(payload);
    } catch (error) {}
  };

  const [column, setColumn] = useState([
    // {
    //   heading: () => "S.No",
    //   cell: (row, i) => {
    //     return i;
    //   },
    //   hidden: false,
    //   width: "20",
    //   id: 1,
    // },
    {
      heading: () => t("common.Action"),
      cell: (row) => (
        <>
          <div>
            <button
              className="emptyBtn"
              onClick={() =>
                navigate(
                  `/admin/customer-detail/${row?.id}/${row?.customer_name}`
                )
              }
            >
              <img src={ViewIcon} alt="ViewIcon" />
            </button>
          </div>
        </>
      ),
      hidden: false,
      // width: "100",
      // align: "center",
      id: 1,
    },
    {
      heading: () => t("customer.CustomerId"),
      cell: (row) => (row["cus_ref_no"] ? row["cus_ref_no"] : "-"),
      hidden: false,
      width: "120",
      align: "left",
      id: 2,
    },
    {
      heading: () => t("customer.CustomerName"),
      cell: (row) => (row["customer_name"] ? row["customer_name"] : "-"),
      hidden: false,
      width: "120",
      align: "left",
      id: 3,
    },
    // {
    //   heading: () => "Last Name",
    //   cell: (row) => (row["last_name"] ? row["last_name"] : "-"),
    //   hidden: false,
    //   width: "120",
    //   align: "left",
    //   id: 3,
    // },
    {
      heading: () => t("common.Country"),
      cell: (row) => {
        // Get the mobile number or default to "-"
        const mobileNumber = row["mobile"] ? row["mobile"] : "-";

        // Clean the number by removing non-digit characters
        const cleanedNumber =
          mobileNumber !== "-" ? mobileNumber.replace(/\D/g, "") : "-";

        // Country code to country name mapping
        const countryMapping = {
          91: "India",
          965: "Kuwait",
          966: "Saudi Arabia",
          971: "United Arab Emirates",
          385: "Croatia",
          "+1": "United States of America",
        };

        // Function to get the country name based on the mobile number
        const getCountryName = (number) => {
          if (number === "-") return "-";

          // Match the cleaned number with country codes
          for (const [code, country] of Object.entries(countryMapping)) {
            if (number.startsWith(code)) {
              return country;
            }
          }

          return "-"; // Default if no match found
        };

        // Get the country name based on the cleaned number
        const countryName = getCountryName(cleanedNumber);

        // Return country name or default "-"
        return <span>{countryName || "-"}</span>;
      },
      hidden: false,
      width: "120",
      align: "left",
      id: 5,
    },
    {
      heading: () => t("customer.Email"),
      cell: (row) => (row["email"] ? row["email"] : "-"),
      hidden: false,
      width: "120",
      align: "left",
      id: 6,
    },
    {
      heading: () => t("customer.Mobile"),
      cell: (row) => {
        // Get mobile number or default to "-"
        const mobileNumber = row["mobile"] ? row["mobile"] : "-";

        // Clean the number by removing non-digit characters
        const cleanedNumber =
          mobileNumber !== "-" ? mobileNumber.replace(/\D/g, "") : "-";

        // Function to format the mobile number
        const formatMobileNumber = (number) => {
          if (number === "-") return "-";

          // Handle numbers starting with specific country codes
          if (
            number.startsWith("965") ||
            number.startsWith("966") ||
            number.startsWith("971") ||
            number.startsWith("385")
          ) {
            return `+${number.substring(0, 3)}-${number.substring(3)}`;
          } else if (number.startsWith("91")) {
            return `+91-${number.substring(2)}`;
          } else {
            return `+${number}`;
          }
        };

        // Get the formatted mobile number
        const formattedNumber = formatMobileNumber(cleanedNumber);

        return <span>{formattedNumber}</span>;
      },
      hidden: false,
      width: "120",
      align: "left",
      id: 7,
    },
    {
      heading: () => t("customer.CreatedDate"),
      cell: (row) => (row["created_Date"] ? row["created_Date"] : "-"),
      hidden: false,
      width: "120",
      align: "left",
      id: 8,
    },
    {
      heading: () => t("common.Status"),
      cell: (row) => {
        console.log("row::: ", row);
        const status = row["active_flag"];
        console.log("status::: ", status);
        let displayText = "";
        let style = {};
        let className = "";

        if (status === true) {
          displayText = "Active";
        } else if (status === false) {
          displayText = "InActive";
        } else if (status === "all") {
          displayText = "ALL Status";
          style = { color: "#28a745", fontWeight: 600 };
        } else {
          displayText = "-";
          style = { fontWeight: 600 };
        }

        return (
          <span
            className={
              row?.active_flag
                ? "fontWeight600 UFOGreen_text"
                : "fontWeight600 errorClr_text"
            }
            style={style}
          >
            {displayText}
          </span>
        );
      },
      hidden: false,
      width: "120",
      align: "left",
      id: 9,
    },
  ]);

  useEffect(() => {
    const customerList = async () => {
      const offset = (currentPage - 1) * itemsPerPage;
      const offsetToUse = offset >= 0 ? offset : 0;

      try {
        const requestBody = {
          offset: offsetToUse,
          limit: itemsPerPage,
          search: searchKey,
          startDate: convertToISOString(range.startDate),
          endDate: convertToISOString(range.endDate),
          statusfilter: status || "all",
        };
        let res = await getCustomerList(requestBody);

        if (res?.status === 200 && res?.data?.statusCode === 200) {
          setList(res?.data?.data);
          setTotalCount(res?.data?.count);
        } else {
          (res?.message || res?.data?.message) &&
            toast.error(res?.message || res?.data?.message);
        }
      } catch (error) {
        if (error?.message) {
          const msg = error?.message?.split(":")?.[1]?.trim();
          console.error(msg);
        }
        console.log(error.message);
      }
    };
    customerList();
  }, [currentPage, itemsPerPage, searchKey, range, setSearchKey, status]);

  return (
    <>
      {/* <div className="mb_24 displayFlex alignItem_center justifyContent_spacebetween">
        <div></div>
        <div></div>
      </div>
      <div> */}
      {/* <div className="mb_24 displayFlex alignItem_center justifyContent_spacebetween"> */}
      <div className="mb_24">
        <h2 className="fontSize20 fontWeight600 blackClr_text">
          {t("customer.CustomerList")}
        </h2>
      </div>
      {/* <div className="displayFlex justifyContent_spacebetween"> */}
      {/* <div className="pr_10"> <DatePicker range={range} setRange={setRange} /></div> */}
      {/* </div> */}
      {/* </div> */}

      {isLoading ? (
        <PaginatedTable
          row={list}
          column={column}
          totalCount={totalCount}
          itemsPerPage={itemsPerPage}
          setItemsPerPage={setItemsPerPage}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          isSearch={true}
          searchKey={searchKey}
          setSearchKey={setSearchKey}
          maxPageNumberLimit={maxPageNumberLimit}
          setMaxPageNumberLimit={setMaxPageNumberLimit}
          minPageNumberLimit={minPageNumberLimit}
          setMinPageNumberLimit={setMinPageNumberLimit}
          setRange={setRange}
          range={range}
          isExport={true}
          isDateRange={true}
          status={status}
          setStatus={setStatus}
          isstatus={true}
          handleExport={() => handleExport()}
        />
      ) : (
        <div style={{ textAlign: "center" }}>Loading...</div>
      )}
    </>
  );
};

export default Customerpage;
